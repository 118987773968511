import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const OriginCover = ( { lang } ) =>
    <StaticImage
        src={"../../../assets/images/cover_chinese_phil.jpg"}
        alt={ lang==="en" ? "Origins of Moral-Political Philosophy in Early China" : "中国先秦道德政治哲学的起源：仁爱、公正与自由之论辩" }
        placeholder="blurred"
        imgStyle={{ objectFit: "contain" }}
        style={{ height: "100%", width: "100%" }}
    />