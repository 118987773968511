import React from "react";
import Book from "../subpage_src/components/Book";
import data from '../data/Data';
import Layout from "../subpage_src/components/Layout";
import {OriginCover} from "../subpage_src/components/partials/book_covers/OriginCover";
import tableOfContents from "../data/table_of_contents/chinese_philosophy_cn.pdf";

const OriginsOfChinesePhil = () => {
    return (
        <Layout lang={"cn"}>
            <Book
                lang={"cn"}
                Cover = {OriginCover}
                bookData = {data.chinese_phil}
                pdfFile={tableOfContents}
            />
        </Layout>
    )

}

export default OriginsOfChinesePhil;